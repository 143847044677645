import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { AVAILABLE_CURRENCIES } from '../../../shared/utils';
import { setOrderAction, setPricing } from '../../../store/actions/orderActions';
import initialState from '../../../store/reducers/initialState';
import AdditionalItemsForm from '../AdditionalItemsForm/AdditionalItemsForm';
import classes from './AIDAForm.module.scss';

const PAGE_TIERS = [
    { id: '-', amount: 0 },
    { id: 'AIDA-ent3', amount: 4_800, minDuration: 5 },
    { id: 'AIDA-ent4', amount: 9_600, minDuration: 3 },
    { id: 'AIDA-ent5', amount: 19_600, minDuration: 2 },
    { id: 'AIDA-ent6', amount: 50_000 },
    { id: 'AIDA-ent7', amount: 100_000 },
    { id: 'AIDA-ent8', amount: 250_000 },
    { id: 'AIDA-ent9', amount: 500_000 },
    { id: 'AIDA-ent10', amount: 1_000_000 },
    { id: 'AIDA-ent11', amount: 2_000_000 },
    { id: 'AIDA-ent12', amount: 3_500_000 },
    { id: 'AIDA-ent13', amount: 6_000_000 },
    { id: 'AIDA-ent14', amount: 10_000_000 }
];

export default function AIDAForm() {
    const dispatch = useDispatch();

    const order = useSelector(state => state.order.data);
    const currency = useSelector(state => state.ui.currency);
    const { duration, pages } = order;

    console.log('Rendering order', order);

    useEffect(() => {
        dispatch(setOrderAction({
            ...order,
            pages: PAGE_TIERS[ 0 ].id,
            duration: 1,
            deployment: 'cloud'
        }));

        return () => {
            dispatch(setPricing({
                priceOneOff: initialState.order.priceOneOff,
                priceYear: initialState.order.priceYear,
                price: initialState.order.price
            }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (order.pages.length > 0) {
            const item = PAGE_TIERS.find(elem => elem.id === order.pages);
            const minDuration = !!item ? item.minDuration || 1 : 1;
            const duration = minDuration > order.duration ? minDuration : order.duration;

            dispatch(setOrderAction({
                ...order,
                duration
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ order.pages ]);

    const handleContractDurationChange = (duration) => {
        dispatch(setOrderAction({
            ...order,
            duration: parseInt(duration, 10)
        }));
    };

    const getContractDurationSelect = () => {
        const options = [];
        const item = PAGE_TIERS.find(elem => elem.id === order.pages);
        const minDuration = !!item ? item.minDuration || 1 : 1;

        for (let i = 1; i <= 5; i++) {
            options.push({
                value: i,
                displayValue: i > 1 ? `${i} years` : `${i} year`,
                disabled: i < minDuration
            });
        }

        return <div className="control">
            <div className="select">
                <select onChange={e => handleContractDurationChange(e.target.value)} value={duration}>
                    {options.map(o => <option key={o.value} value={o.value}
                                              disabled={o.disabled}>{o.displayValue}</option>)}
                </select>
            </div>
        </div>;
    };

    const handlePagesIncrement = () => {
        let index = PAGE_TIERS.indexOf(PAGE_TIERS.find(elem => elem.id === pages));
        if (index < Object.keys(PAGE_TIERS).length - 1) {
            index++;
        }

        dispatch(setOrderAction({
            ...order,
            pages: PAGE_TIERS[ index ].id
        }));
    };

    const handlePagesDecrement = () => {
        let index = PAGE_TIERS.indexOf(PAGE_TIERS.find(elem => elem.id === pages));
        if (index > 0) {
            index--;
        }

        dispatch(setOrderAction({
            ...order,
            pages: PAGE_TIERS[ index ].id
        }));
    };

    const getPagesSelect = () => {
        const { thousandSeparator, decimalSeparator } = AVAILABLE_CURRENCIES[ currency ];

        return <div className="field has-addons">
            <div className="control">
                <button onClick={handlePagesDecrement} className="button is-primary"
                        disabled={pages === PAGE_TIERS[ 0 ].id}>
                    <span className="icon">
                        <i className="fas fa-minus" />
                    </span>
                </button>
            </div>

            <div className="control">
                <NumberFormat
                    value={pages && PAGE_TIERS.find(elem => elem.id === pages).amount}
                    displayType={'input'}
                    thousandSeparator={thousandSeparator}
                    decimalSeparator={decimalSeparator}
                    className="input"
                    style={{ textAlign: 'center' }}
                    readOnly={true}
                />
            </div>
            <div className="control">
                <button onClick={handlePagesIncrement} className="button is-primary"
                        disabled={pages === PAGE_TIERS[ PAGE_TIERS.length - 1 ].id}>
                    <span className="icon">
                        <i className="fas fa-plus" />
                    </span>
                </button>
            </div>
        </div>;
    };

    return (
        <>
            <div className={classes.Options}>
                <div className="field">
                    <label className="label">Pages processed / year</label>
                    <div className="control">
                        {getPagesSelect()}
                    </div>
                </div>
                <div className="field">
                    <label className="label">Contract duration</label>
                    {getContractDurationSelect()}
                </div>
            </div>
            <AdditionalItemsForm />
        </>
    );
}
