import React from 'react';
import AIDAForm from './AIDAForm/AIDAForm';
import Summary from './Summary/Summary';

export default function OrderForm() {
    return (
        <div className="container">
            <h1>AIDA order form</h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box">
                        <AIDAForm />
                    </div>
                </div>
                <div className="column is-4">
                    <Summary/>
                </div>
            </div>
        </div>
    )
}
